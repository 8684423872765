import React, { FC } from 'react';
import { FallbackProps as IErrorProps } from 'react-error-boundary';

export const ErrorPage: FC<IErrorProps> = ({ error }) => {
  return (
    <div className="flex h-full w-full flex-row items-center justify-center text-gray-400 gap-3">
      <p className="text-xl">Something went wrong:</p>
      <p className="text-base">{error.message}</p>
    </div>
  );
};
